import { render, staticRenderFns } from "./l-footer.vue?vue&type=template&id=26a8daa8&scoped=true&"
import script from "./l-footer.vue?vue&type=script&lang=js&"
export * from "./l-footer.vue?vue&type=script&lang=js&"
import style0 from "./l-footer.vue?vue&type=style&index=0&id=26a8daa8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a8daa8",
  null
  
)

component.options.__file = "l-footer.vue"
export default component.exports